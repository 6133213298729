import React from 'react';
import './HomePage.css';

const HomePage = () => {
    const services = [
        { name: 'Wichtel', url: 'https://wichtel.acksmi.de', icon: '🎁' },
        { name: 'Poll', url: 'https://poll.acksmi.de', icon: '📊' },
        { name: 'Files', url: 'https://files.acksmi.de', icon: '📂' },
        { name: 'FCP', url: 'https://fcp.acksmi.de', icon: '💻' }
    ];

    return (
        <div className="page-container">
            <div className="content">
                <h1 className="title">acksmi.de</h1>
                <p className="subtitle">Einfach machen</p>
                <div className="services">
                    {services.map((service, index) => (
                        <a key={index} href={service.url} className="service-card" target="_blank"
                           rel="noopener noreferrer">
                            <div className="service-icon">{service.icon}</div>
                            <div className="service-name">{service.name}</div>
                        </a>
                    ))}
                </div>
            </div>
            <footer className="footer">
                <p>&copy; 2024 acksmi.de | Designed by acksmi</p>
            </footer>
        </div>
    );
};

export default HomePage;
